.absolute{
    position: absolute;
  }

  .horario-container{
    height: 30px;
    width: 80px;
    border: 1px solid rgb(96, 94, 92);
  }

  .minutos{
      left: 50px;
      border-left: none;
      width: 40px;
      /* width: 45px; */
  }

  .minutos>div>span{
    border: none !important;
    background-color: transparent !important;
    text-overflow: clip;
    overflow: hidden;
  }

  .horas{
    width: 80px;
  }

  .horas>div>span{
    border: none !important;
    background-color: transparent !important;
    text-overflow: clip;
    overflow: hidden;
  }

  .dosPuntos{
    top: 37px;
  left: 44px;
  }

  .minutos>div>span:focus{
    border: 0px !important
  }

  .minutos > .ms-Dropdown:focus{
    border: 0px !important
  }

  .minutos > .ms-Dropdown:active{
    border: 0px !important
  }

    .no-margin {
      margin: 0px !important;
    }
