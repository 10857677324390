.collaborator-view-container {
  margin-top: 25px;
}

.collaborator-toggle {
  margin-top: 15px;
  margin-left: 8px;
}

.collaborator-email {
  margin-top: -10px;
}

/* .persona-collaborator {
  text-align-last: center;
} */

.collaborator-fullName {
  float: right;
}

.hidden {
  display: none;
}

.persona-class {
  justify-content: center;
  /* margin-bottom: -20px; */
}

.text-icon-collaborator {
  font-size: 12px;
}

.persona-detail-class {
  margin-top: -30px;
  margin-bottom: -30px;
}

/* .persona-class > div {
  margin-top: -50px;
} */

/* .persona-collaborator > div > div {
  justify-content: center;
} */

.persona-collaborator-text {
  font-size: 11px;
  color: gray;
}

.persona-collaborator-title {
  font-size: 80%;
  color: gray;
}

.collaborator-area {
  font-size: 120%;
}

.areaYDireccion {
  font-size: 16px;
}

/*  estructura del formulario  #faf9f8 */

.update_body_padre{
  padding: 10px 20px;
  background-color: #faf9f8;
}

.update_body_informacion_general{
  max-width: 650px;
  height: 550px;
  border: 1px 0px 0px 0px;
  min-width: 500px;
  background-color: #ffffff;
}

.update_body_estructura_organizacional{
  margin-top: 15px;
  max-width: 609px;
  min-width: 500px;
  height: 110px;
  border: 1px 0px 0px 0px;
  background-color: #ffffff;
}

.update_body_proyecto_enlace{
  margin-top: 15px;
  max-width: 609px;
  min-width: 500px;
  height: 110px;
  border: 1px 0px 0px 0px;
  background-color: #ffffff;
}

@media (min-width: 1080px) {
  .update_body_padre {
    display: grid;
    grid-template-columns: 50% 49%;
  }

  .update_body_informacion_general {
    margin-right: 10px;
    grid-row: 1/4;
  }
  .update_body_estructura_organizacional{
    margin-top: 0px;
  }
  .update_body_proyecto_enlace{
    margin-top: 10px;
  }
}

.update_date_derecho_form{
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 400px;
}
.update_date_derecho_form label{
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.42px;
  text-align: left;
  color: #000000;
}