.margin-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.projects-table {
  display: block;
  max-height: 350px; /* Just for the demo          */
  overflow-y: auto; /* Trigger vertical scroll    */
  overflow-x: hidden; /* Hide the horizontal scroll */
}

.defense-file-container-desc {
  margin-bottom: 5px;
}

.defense-pivot {
}

.pivotItem {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
}

.defense-file-title {
  font-size: 15px;
}

.ms-Pivot-link {
  height: 74px !important;
}
