.treeChart{
  width: 100%;
}

/* .treeChart > svg, .treeChart > g {
  width: 100vw;
  margin: auto;
  overflow: scroll;
} */

/* .svgTree{
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .svgNode{
  margin: 0 auto;
  margin-left: 1000px;
} */