.size-document-card{
  min-width: 50% !important;
  margin-bottom: 5px !important;
  min-height: 145px;
}

.proyect-title{
  font-size: 11px !important;
}

.size-logo{
  min-height: 145px;
}
