.menu-calendar {
  // height: 800px;
  border-right: 1px solid rgb(238, 238, 238);
  /* border-top: 1px solid rgb(238, 238, 238); */
  padding: 0.25rem;
  overflow: hidden;
}

.menu-calendar-control {
  padding: 0px;
}

.toggle-menu {
  @media only screen and (min-width: 1023px) {
    padding-left: 20px;
  }
}

.row-flex-calendar {
  @media only screen and (min-width: 1023px) {
    display: flex;
  }
}

.datepicker-mobile-menu-calendar {
  padding: 0px 8px;
}

.padding-left-20 {
  padding-left: 20px;
}

.px-0 {
  padding: 0;
}

.mt-1 {
  margin-top: 5px;
}

.fit-content {
  width: fit-content !important;
}

.margin-proyecto {
  margin-left: 27px;
  font-size: 14px;
}

.title-callout {
  font-weight: 500;
}

.calendar-event-content-container {
  height: inherit;
  width: -webkit-fill-available;
}

/* ========================================================================
     Component: calendar
 ========================================================================== */
$info: #eef3f5;
.calendar-app {
  font-size: 0.8rem !important;
  padding: 10px;
  margin-bottom: 35px;
  .fc-state-highlight,
  .fc-day:hover {
    background-color: #f7f8f9;
  }

  .fc-day-today {
    background-color: transparent !important;

    .fc-day-number {
      color: $info;
    }
  }

  .fc-day-number {
    font-size: 18px;
    font-weight: bold;
    color: #a1a2a3;
  }

  .fc-event {
    color: #fff !important;
  }

  // Allow to drag event outside the calendar
  .fc-view {
    overflow: visible;
  }

  .fc-toolbar h2 {
    font-size: 1.3em;
    display: inline;
    position: relative;
    top: 6px;
  }

  .text-icon-toolbar {
    font-size: 19px;
    margin-right: 0.25rem;
    position: relative;
    top: 0.2rem;
  }
  .fc-time-grid .fc-slats .fc-minor td {
    border: none;
  }

  .fc-hoursMiniPanel-button {
    display: inline;
    margin-top: 3px;
  }

  // @include media-breakpoint-down(lg) {

  //   // hide views buttons
  //   .fc-right {
  //     display: block;
  //   }

  //   .fc-center h2 {
  //     font-size: 1em;
  //   }

  //   .fc-today-button {
  //     display: none;
  //   }
  // }

  // @include media-breakpoint-down(md) {

  //   // hide views buttons and date buttons
  //   .fc-right,
  //   .fc-left {
  //     display: block;
  //   }

  //   .fc-center {
  //     display: none;
  //   }

  //   .fc-today-button {
  //     display: none;
  //   }
  // }
}

// Calendar app styles
// -----------------------
.external-events {
  margin: 0;

  > div {
    // display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 6px 10px;
    color: #fff;
    font-size: 11px;
    border-radius: 2px;
    cursor: move;
  }

  &:empty {
    content: "EMPTY";
  }
}

// Remove events drop area

#external-event-color-selector {
  .circle.selected {
    border: 3px solid rgba(255, 255, 255, 0.5);
  }
}

.actividad-card-collapsed {
  transition: width 1s ease;
  // position: relative;
  // display: block;
  overflow: hidden;
  // right: 45%;
  position: relative;
  left: 3%;
  width: 0;
}

.actividad-card-active {
  transition: width 1s ease;
  position: relative;
  left: 2%;
  width: 41.6%;
}

.calendar-card-full {
  transition: width 1s ease;
  // display: inline-block;
  width: 97%;
}

.calendar-card-active {
  transition: width 1s ease;
  width: 56.3%;
}

.actividad-show-active {
  transition: right 1s ease;
  position: relative;
  right: -2%;
}

.actividad-show-collapsed {
  transition: right 1s ease;
  position: relative;
  right: -10%;
}

.actividad-show-card {
  position: relative;
  right: -5%;
}

tr.fc-list-item {
  cursor: pointer;
}

.calendar-event-title-small {
  font-size: 11px !important;
  display: block;
  position: relative;
  top: 2px;
  left: 5px;
  overflow: hidden;
}

.calendar-event-title {
  font-size: 11px !important;
}

.fc-center {
  font-size: 1em !important;
}

.fc-slats td {
  // height: 80px !important;
  height: 20px !important;
}

.fc-timegrid-slot {
  height: 30px !important;
}

.calendar-event-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.calendar-event-inner-wrapper {
  width: 100%;
  height: 100%;
}

.calendar-event-sidebar {
  width: 7px;
  height: 100%;
}

.custom-act-15-min {
  position: relative !important;
  top: -3px !important;
  margin-top: 0px !important;
}

.fc th {
  text-align: center;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
  Roboto, "Helvetica Neue", sans-serif !important;
  font-weight: 500 !important;
}
.fc {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
  Roboto, "Helvetica Neue", sans-serif !important;
  font-weight: 500 !important;
}

.fc-button,
.fc-button-primary {
  outline: transparent !important;
  position: relative !important;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
  Roboto, "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  box-sizing: border-box !important;
  border: 1px solid rgb(138, 136, 134) !important;
  display: inline-block !important;
  text-decoration: none !important;
  text-align: center !important;
  cursor: pointer !important;
  padding: 0px 16px !important;
  border-radius: 1px !important;

  border: 0px !important;
  height: 32px;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(50, 49, 48) !important;
  user-select: none !important;
}

.fc-button-primary:hover {
  // background-color: rgb(191, 191, 184) !important;
  // color: rgb(32, 31, 30) !important;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: 0 0 0 0 !important;
}

.fc-button-primary:focus {
  box-shadow: 0 0 0 0 !important;
}

.fc--button {
  display: none !important;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  background-color: rgb(191, 191, 184) !important;
  color: rgb(0, 0, 0) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-white {
  color: white;
}

.display-flex {
  display: flex;
}

.padding-to-div-for-button {
  padding: 0px 24px;
}

.padding-to-div-for-team {
  padding: 5px 28px 0px 28px;
}

.margin-for-icon-in-button {
  margin-right: 4px;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.animation-height {
  transition: height 0.25s ease-in;
}

.desc-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: "14px";
  margin-bottom: "5px";
}

.margin-documents {
  margin-top: 10px;
}

.checked-event {
  background-color: #deecf9;
}

.pending-event {
  background-color: #ffcbcf;
}

.deleted-event {
  background-color: #ddd;
}

.license-event {
  background-color: #fbf2dc !important;
}


.outlook-event {
  background-color: rgb(255, 251, 142);
}

.ml-1 {
  margin-left: 5px;
}

.hours-checked {
  margin-top: 10px;
}

.callout-panel-horas {
  width: 340 !important;
  padding: 20px 24px !important;
}

.checkmark-color {
  color: rgb(108, 229, 97);
}

.cancel-color {
  color: rgb(232, 67, 81);
}

$red: #ff0000;
$gris: #878887;
$yellow: #ffff00;
$blue: #0078D4;

@mixin status-square($color) {
  width: 10px;
  height: 10px;
  background-color: $color;
  display: inline-block;
  margin-right: 8px;
  border-radius: 2px;
}

.status-square {
  &.status-red {
    @include status-square($red);
  }
  &.status-gris {
    @include status-square($gris);
  }
  &.status-yellow {
    @include status-square($yellow);
  }
  &.status-blue {
    @include status-square($blue);
  }
}

