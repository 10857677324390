.organizationChartDropdownPath {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

button > span.flexContainer-283 {
  display: inline;
}

button > span.flexContainer-283 > span {
  display: inline;
}

.organizationChartContainer:has(> span:has(> span)) {
  display: inline;
}

.organizationChartStack {
  display: inline !important;
}

.organizationChartSpan {
  position: absolute;
  right: 10px;
}
