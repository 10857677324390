.float-right {
  float: right;
}

.pagination-button-wrapper {
  margin-top: 1.5rem;
}
.pagination-btn {
  min-width: 40px !important;
}
.d-inline {
  display: inline !important;
}
.grid-card-list {
  margin-top: 15px;
  padding: 0;
}

.mt-more-filters {
  margin-top: 22px;
}

.padding-list {
  padding: 1rem;
}

.icon-pagination-btn {
  font-size: 12px;
}

.detailview-height div .ms-DetailsList-headerWrapper .ms-DetailsHeader {
  padding-top: 0px;
}
