.clickable {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.full-height {
  /* height: 100%; */
  overflow: auto;
}

.padding-1 {
  padding: 1rem;
}

.pad-main-body {
  padding-left: 8px;
  padding-top: 15px;
}

.border-bottom-nav {
  border-bottom: 1px solid rgb(238, 238, 238);
}

.text-title-btn {
  font-size: 16px;
}
