@keyframes clickAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .icon-clicked {
    animation: clickAnimation 0.3s ease-in-out;
    color: aqua;
  }