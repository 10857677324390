.principal-item {
  outline: transparent;
  position: relative;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
    Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  background-color: transparent;
  border: none;
  width: 100%;
  height: 36px;
  line-height: 36px;
  display: block;
  cursor: pointer;
  padding: 0px 8px 0px 4px;
  text-align: left;
}

.principal-item:hover {
  background-color: rgb(237, 235, 233);
}

.with-secondary {
  min-height: 55px;
}

.d-block {
  display: block;
}

.secondary-text-dropdown {
  margin-top: -20px;
  color: grey;
  font-size: 11px;
  text-overflow: ellipsis;
}
