.text-wrapper,
.button-wrapper {
  margin-top: 0.7rem;
}

.primary-button {
  margin-right: 1rem;
}

.text-icon {
  font-size: 19px;
  margin-right: 0.5rem;
  position: relative;
  top: 0.2rem;
}

.uploaded-by-persona {
  margin-top: 1rem !important;
}
