.collaborator_create_hader_buton {
  height: 37px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.collaborator_create_hader_buton .Button_back {
  border: none;
  background-color: #faf9f8;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  size: 12px;
  font-weight: 500;
  color: #727b78;
}
.collaborator_create_hader_buton .Button_view {
  border: none;
  background-color: #0078d4;
  color: #ffffff;
  display: flex;
  size: 12px;
  font-weight: 500;
  font-family:"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}
/* formulario Inforamcion genral*/
.titulo_form_create {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.53px;
  text-align: left;
  padding: 5px 5px;
}
.form_informacion_general {
  width: 609px;
  height: 677px;
  border: 1px 0px 0px 0px;
}

/*input reusable */

.input_reusable {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.input_reusable label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.42px;
  text-align: left;
  color: #000000;
  margin-bottom: 1px;
}

.label_reusable label{
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.42px;
  text-align: left;
  color: #000000;
  margin-bottom: 1px;
}

.date_derecho_form{
  display: flex;
  flex-direction: column;
  width: 230px;
}
.date_derecho_form label{
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.42px;
  text-align: left;
  color: #000000;
}
.input_desaparese{
  display: none;
}
/* caja de formulario */

.form_informacion_general{
  padding: 0px 20px;
  width: 100%;
  height: 98%;
}

/*cuerpo de formulario */

.form_informacion_general_cuerpo{
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    gap: 50px; /* dinamico*/
}

.cuerpo_izquierdo_form{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  gap: 8px;
}

/* estilos del error de formulario*/

.error_stilos{
  color: #ff0000;
height: 15px;
margin-bottom: -40px;
font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
font-size: 10px;
font-weight: 500;
line-height: 14.93px;
text-align: left;
}

.error_stilos_practicante{
margin-top: 2px;
color: #ff0000;
height: 15px;
font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
font-size: 10px;
font-weight: 500;
line-height: 14.93px;
text-align: left;
}

.error_stilos_Organizacional{
  margin-top: -3px;
  color: #ff0000;
  height: 15px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 14.93px;
  text-align: left;
  }

.error_TextFile_proyectofijo{
  margin-top: 5px;
  color: #ff0000;
  height: 15px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 14.93px;
  text-align: left;
}