.Customodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .Customodal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 245px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .Customodal-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .Customodal-header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .Customodal-body p {
    margin: 15px 0;
    font-size: 14px;
  }
  
  .Customodal-footer {
    display: flex;
    gap: 25px;
    justify-content: center;
  }
  
  .Custobtn-confirm {
    background-color: #0078d4;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .Custobtn-cancel {
    background-color: white;
    color: black;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  


  .padre_loader_Proyect{
    display: flex;
    flex-wrap: wrap;
   align-items: center;
   justify-content: center;
  }

  .loader_Proyect {
    display: block;
    --height-of-loader: 4px;
    --loader-color: #0071e2;
    width: 130px;
    height: var(--height-of-loader);
    border-radius: 30px;
    background-color: rgba(0,0,0,0.2);
    position: relative;
  }
  
  .loader_Proyect::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: moving 1s ease-in-out infinite;
    ;
  }
  
  @keyframes moving {
    50% {
      width: 100%;
    }
  
    100% {
      width: 0;
      right: 0;
      left: unset;
    }
  }


