.button-small {
  max-width: 100px;
  min-width: 0px !important;
  /* padding: 0px 0px !important; */
}

.smaller-text-container {
  font-size: 14px;
  white-space: nowrap;
  display: flex;
}

.projects-table-detail {
  font-size: 15px;
}

.margin-left-group-name {
  margin-left: 10px;
}

.settlement-group-name {
  width: 220px;
}

.settlement-group-period {
  width: 220px;
}

.settlement-group-collaborators {
  width: 120px;
}

.settlement-group-items {
  width: 120px;
}

.settlement-group-amount {
  width: 120px;
}
