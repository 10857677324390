.collaborator-email{
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 11px !important;
}

.collaborator-details{
    margin-top: -7px;
    font-size: 9px !important;
    padding: 0px, 16px !important;
}