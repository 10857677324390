.notification-alert {
  margin-right: -13px;
   background-color: rgb(255, 81, 8);
   top: 14px;
   border-radius: 50%;
   height: 15px;
   width: 15px;
   font-size: 10px;
   text-align: center;
   color: white;
 }
 
 @media only screen and (min-width: 500px) {
   .notification-alert {
     right: 25px; /* Ajusta esto según tu diseño */
   }
 }
 
 @media only screen and (max-width: 500px) {
   .notification-alert {
     left: calc(100% - 25px); /* Ajusta esto según tu diseño */
   }
 }
 .padre_header{
   display: flex;
   flex-direction: row;
   width: 100%;
   min-height: 56px;
   padding: 1rem;
 }
 
 
 .header_hijo1{
     align-items: center;
     display: flex;
     flex-direction: row;
     height: 30px;
     justify-content: 'end'
 }
 .header_hijo2{
   align-items: center;
   display: flex;
   flex-direction: row;
   height: 30px;
   justify-content: 'end'
 }
 
 @media (max-width: 768px) {
   .padre_header{
     flex-direction: unset;
     flex-wrap: wrap;
   }
 }

/* ul .ms-Nav-navItems > li > div > a {
  padding: 0px;
  margin: 0px 4px;
} */

@media only screen and (min-width: 1370px) {
  .iconButtonAppNavClass {
    visibility: hidden;
  }
}

@media only screen and (max-width: 1370px) {
  a[title="Hours by company"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Horas por empresa"] {
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Pre-settlement"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Horas por proyecto"] {
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Hours by project"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Horas por sector"] {
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Hours by sector"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Preliquidación"] {
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Liquidación indirecta"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Liquidación directa"] {
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Indirect settlement"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Direct settlement"] {
    padding: 0px;
    margin: 0px 4px;
  }

  button[title="Liquidación indirecta"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  button[title="Indirect settlement"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Archivo de defensa"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Defense file"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Defensa Masiva"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Massive Defense"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  button[title="Defensa Masiva"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  button[title="Massive Defense"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  button[title="Archivo de defensa"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  button[title="Defense file"] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Docs oblig. por proy."] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  button[title="Docs oblig. por proy."] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  a[title="Docs oblig. por proy."] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }

  button[title="Docs oblig. por proy."] {
    /* padding: 10px; */
    padding: 0px;
    margin: 0px 4px;
  }
}

/* Horas por sector

Preliquidación */
