.file-uploaded-blockui-message {
  color: #29a12d;
}

.no-documents-message-container {
  padding: 4rem 1rem;
}

.no-document-selected-message-wrapper {
  padding-top: 5.5rem;
}

.add-folder-icon {
  margin-right: 1rem;
}
