.collaborator_create_hader_buton {
  background-color: #faf9f8;
  padding: 6.4px 6.4px 6.4px 25px;
}

.collaborator_create_bt {
  border: none;
  background-color: #0078d4;
  padding: 0px 4px;
  color: #eff6fc;
  transition: background-color 0.3s ease;
}

.collaborator_create_bt:hover {
  color: #eff6fc;
  background-color: #0078d4c2;
  opacity: 0.8;
}
.collaborator_create_bt:active {
  background-color: #eff6fc;
}

.collaborator_create_icon {
  margin-right: 6px;
}

/*collaborator_create page */
.collaborator_create {
  height: 910px;
  background-color: #faf9f8;
}
.body_form {
  padding: 10px 20px;
  gap: 15px;
}

.header_collaborator_create {
  border-bottom: solid 1px #dbdbdbd1;
}

.body_nuevo_colaborador {
  display: flex;
  align-items: center;
  max-width: 1242px;
  height: 50px;
  padding-left: 15px;
  gap: 8px;
  background-color: #ffffff;
  color: #383939;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 22.79px;
  text-align: left;
}

.body_informacion_general {
  max-width: 609px;
  height: 750px;
  border: 1px 0px 0px 0px;
  background-color: #ffffff;
}

.body_estructura_organizacional {
  max-width: 609px;
  height: 426px;
  border: 1px 0px 0px 0px;
  background-color: #ffffff;
}

.body_proyecto_enlace {
  max-width: 609px;
  height: 305px;
  border: 1px 0px 0px 0px;
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .body_form {
    display: grid;
    grid-template-columns: 49% 49%;
  }

  .body_nuevo_colaborador {
    grid-column: 1/3;
  }
  .body_informacion_general {
    grid-row: 2/4;
  }
}


/*modal  */
.collaborator_modal_header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.header_azul_modal{
  height: 4px;
  background-color: #0078d4;
  width: 100%;
}
.loading-container {
  text-align: center;
  margin-top: 40px;
}

.collaborator_linea_blanca {
  width:250px;
  height: 2.5px;
  background-color: rgb(235, 233, 233);
}

.collaborator_color_azul {
  margin-top: -2px;
  width: 70px;
  height: 2px;
  background: linear-gradient(to right, rgba(0, 120, 212, 0) 0%, rgba(0, 120, 212, 0.5) 30%, rgba(0, 120, 212, 1) 50%, rgba(0, 120, 212, 0.5) 70%, rgba(0, 120, 212, 0) 100%);
  animation: desplazar 3s linear infinite;
  animation-delay: 1s;
}

@keyframes desplazar {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(250%);
  }
}

/* toast exit*/

.toast_container_collaborator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #DFF6DD;
  height: 33px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 17.92px;
  text-align: left;
  padding: 0 10px;
  color:#59605E;
}

.toast_message_collaborator {
  display: flex;
  align-items: center;
}

.icon_toast_collaborator{
  margin-right: 8px;
}

/* toast erro  */
.toast_container_collaborator_error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F6D8DB;
  height: 33px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 17.92px;
  text-align: left;
  padding: 0 10px;
  color:#59605E;
  ;
}

.toast_message_collaborator_error {
  display: flex;
  align-items: center;
}

.icon_toast_collaborator_error{
  margin-right: 8px;
  color: red;
}

