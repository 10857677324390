.bg-white-h100 {
  background-color: white;
  height: 100%;
}

.container-config-project {
  margin-top: 0.5rem;
}

.iconForTeam {
  color: gray !important;
}
.iconForTeamSelected {
  color: rgb(255, 82, 9) !important;
}
.iconResponsible {
  font-size: 23px;
}

.project-config-data-row {
  display: flex;
}

.project-config-section-title {
  font-size: 15px;
  font-weight: bold;
}

.error-text-red {
  color: red;
}
